<template>
  <register></register>
</template>

<script>
import Register from './../../components/security/register';

export default {
  data: () => ({}),
  components: {
    Register
  }
}
</script>