var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"security",attrs:{"id":"register"}},[_c('v-main',[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('v-card',{staticClass:"mx-auto py-0",attrs:{"max-width":"1000","elevation":"0","rounded":"0","color":"rgba(255,255,255,0)"}},[_c('v-row',{staticClass:"shadow-one",attrs:{"no-gutters":""}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"third d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"py-0 mx-auto my-auto",attrs:{"elevation":"0","rounded":"0","max-width":"400","color":"rgba(255,255,255,0)"}},[_c('v-img',{attrs:{"width":"400","src":require('@/assets/img/register.png')}})],1)],1):_vm._e(),_c('v-col',{staticClass:"white",attrs:{"cols":"12","sm":_vm.$vuetify.breakpoint.mdAndUp ? '6' : '12'}},[_c('v-card',{staticClass:"pt-4 mx-auto mb-5",attrs:{"elevation":"0","rounded":"0","max-width":"470"}},[_c('v-card-text',{staticClass:"mb-3 pb-2 px-8 text-center"},[_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"mb-5"},[_c('v-img',{attrs:{"src":require('@/assets/img/logo.png'),"width":"60"}})],1)]),_c('div',{staticClass:"text-h6 primary--text"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("security.register.title"))+" ")])]),_c('div',{staticClass:"text-body-2 grey--text"},[_vm._v(" "+_vm._s(_vm.$t("security.register.description"))+" ")])]),_c('v-card-text',{staticClass:"pb-1 px-8"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mb-1 font-weight-bold",attrs:{"append-icon":"mdi-account-circle-outline","name":"firstName","label":((_vm.$i18n.t(
                              'security.fields.first_name.title'
                            )) + " *"),"autocomplete":"off","outlined":"","dense":"","error-messages":_vm.firstNameErrors,"maxlength":"100"},on:{"input":function($event){return _vm.delayTouch(_vm.$v.form.firstName)},"blur":_vm.$v.form.firstName.$touch},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mb-1 font-weight-bold",attrs:{"append-icon":"mdi-account-circle-outline","name":"lastName","label":((_vm.$i18n.t(
                              'security.fields.last_name.title'
                            )) + " *"),"autocomplete":"off","outlined":"","dense":"","error-messages":_vm.lastNameErrors,"maxlength":"100"},on:{"input":function($event){return _vm.delayTouch(_vm.$v.form.lastName)},"blur":_vm.$v.form.lastName.$touch},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})],1)],1),_c('v-row',{staticClass:"pb-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"mb-1 font-weight-bold",attrs:{"append-icon":"mdi-at","name":"email","label":((_vm.$i18n.t(
                              'security.fields.email.title'
                            )) + " *"),"type":"email","autocomplete":"off","outlined":"","dense":"","error-messages":_vm.emailErrors,"maxlength":"100"},on:{"input":function($event){return _vm.delayTouch(_vm.$v.form.email)},"blur":_vm.$v.form.email.$touch},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('v-text-field',{staticClass:"mb-1 font-weight-bold",attrs:{"name":"phone","label":((_vm.$i18n.t(
                              'security.fields.phone.title'
                            )) + " *"),"type":"phone","autocomplete":"off","outlined":"","dense":"","error-messages":_vm.phoneErrors,"maxlength":"20"},on:{"input":function($event){return _vm.delayTouch(_vm.$v.form.phone)},"blur":_vm.$v.form.phone.$touch},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticStyle:{"width":"32px"}},[(_vm.countryCode)?_c('span',{staticClass:"flag-icon",class:("flag-icon-" + (_vm.countryCode.toLowerCase())),staticStyle:{"font-size":"1.9rem"}}):_c('span',[_c('v-icon',[_vm._v("mdi-phone-outline")])],1)])]},proxy:true}]),model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),(_vm.countryCode === 'CM')?_c('v-select',{staticClass:"mb-1 font-weight-bold",attrs:{"items":_vm.$objectPath.get(_vm.regions, _vm.$i18n.locale),"name":"region","dense":"","error-messages":_vm.regionErrors,"outlined":"","label":((_vm.$t('security.fields.region.title')) + " *")},on:{"input":function($event){return _vm.delayTouch(_vm.$v.form.region)},"blur":_vm.$v.form.region.$touch},model:{value:(_vm.form.region),callback:function ($$v) {_vm.$set(_vm.form, "region", $$v)},expression:"form.region"}}):_vm._e(),_c('v-text-field',{staticClass:"mb-1 font-weight-bold",attrs:{"append-icon":!_vm.form.password
                                ? 'mdi-lock-outline'
                                : 'mdi-eye-outline',"name":"password","label":((_vm.$t(
                              'security.fields.password.title'
                            )) + " *"),"type":_vm.passwordType,"autocomplete":"off","outlined":"","dense":"","maxlength":"30","error-messages":_vm.passwordErrors},on:{"input":function($event){return _vm.delayTouch(_vm.$v.form.password)},"blur":_vm.$v.form.password.$touch,"click:append":function($event){return _vm.changePasswordType()}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('v-text-field',{staticClass:"mb-1 font-weight-bold",attrs:{"append-icon":"mdi-lock-outline","name":"confirmPassword","label":((_vm.$t(
                              'security.fields.confirm_password.title'
                            )) + " *"),"type":_vm.passwordType,"autocomplete":"off","outlined":"","dense":"","maxlength":"30","error-messages":_vm.confirmPasswordErrors},on:{"input":function($event){return _vm.delayTouch(_vm.$v.form.confirmPassword)},"blur":_vm.$v.form.confirmPassword.$touch},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}}),_c('v-checkbox',{staticClass:"mt-0 text-subtitle-2 pt-0 terms mb-6",attrs:{"color":"primary","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('a',{staticClass:"text-subtitle-2 font-weight-bold ml-1",attrs:{"href":_vm.termsLink,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(_vm.$t("security.fields.terms.title")))])]},proxy:true}]),model:{value:(_vm.term),callback:function ($$v) {_vm.term=$$v},expression:"term"}})],1)],1)],1),_c('v-card-actions',{staticClass:"mt-1 px-8 mb-0 align-center"},[_c('v-btn',{attrs:{"color":"primary","type":"submit","block":"","loading":_vm.loading}},[_c('span',{staticClass:"px-2 font-weight-bold text-subtitle-2"},[_c('span',{staticClass:"secondary-font"},[_vm._v(" "+_vm._s(_vm.$t("security.btn.register.title"))+" ")])])])],1),_c('v-card-text',{staticClass:"text-center py-0 my-2"},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"1.05rem"}},[_c('span',[_vm._v(_vm._s(_vm.$t("security.btn.login.description")))]),_c('router-link',{staticClass:"ml-2",attrs:{"text":"","to":_vm.$i18n.getRouteLink('login')}},[_vm._v(_vm._s(_vm.$t("security.btn.login.title")))])],1)]),_c('v-card-actions',{staticClass:"mb-2 pb-0 mt-8 px-0"},[_c('v-spacer'),_c('lang',{attrs:{"btnOptions":{ 'x-small': true }}})],1)],1)],1)],1)],1),_c('copyright')],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }