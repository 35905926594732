<template>
  <v-app id="register" class="security">
    <v-main>
      <v-container fluid fill-height>
        <v-row>
          <v-col cols="12">
            <v-form autocomplete="off" @submit.prevent="register">
              <v-card
                max-width="1000"
                elevation="0"
                class="mx-auto py-0"
                rounded="0"
                color="rgba(255,255,255,0)"
              >
                <v-row no-gutters class="shadow-one">
                  <v-col
                    cols="12"
                    sm="6"
                    v-if="$vuetify.breakpoint.mdAndUp"
                    class="third d-flex"
                  >
                    <v-card
                      elevation="0"
                      class="py-0 mx-auto my-auto"
                      rounded="0"
                      max-width="400"
                      color="rgba(255,255,255,0)"
                    >
                      <v-img
                        width="400"
                        :src="require('@/assets/img/register.png')"
                      ></v-img>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    class="white"
                    :sm="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                  >
                    <v-card
                      elevation="0"
                      class="pt-4 mx-auto mb-5"
                      rounded="0"
                      max-width="470"
                    >
                      <v-card-text class="mb-3 pb-2 px-8 text-center">
                        <div class="d-flex justify-center">
                          <div class="mb-5">
                            <v-img
                              :src="require('@/assets/img/logo.png')"
                              width="60"
                            ></v-img>
                          </div>
                        </div>
                        <div class="text-h6 primary--text">
                          <span class="font-weight-bold">
                            {{ $t("security.register.title") }}
                          </span>
                        </div>
                        <div class="text-body-2 grey--text">
                          {{ $t("security.register.description") }}
                        </div>
                      </v-card-text>
                      <v-card-text class="pb-1 px-8">
                        <v-row>
                          <v-col cols="12" md="6" class="py-0">
                            <v-text-field
                              append-icon="mdi-account-circle-outline"
                              name="firstName"
                              v-model="form.firstName"
                              :label="`${$i18n.t(
                                'security.fields.first_name.title'
                              )} *`"
                              autocomplete="off"
                              outlined
                              dense
                              class="mb-1 font-weight-bold"
                              @input="delayTouch($v.form.firstName)"
                              @blur="$v.form.firstName.$touch"
                              :error-messages="firstNameErrors"
                              maxlength="100"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" class="py-0">
                            <v-text-field
                              append-icon="mdi-account-circle-outline"
                              name="lastName"
                              v-model="form.lastName"
                              :label="`${$i18n.t(
                                'security.fields.last_name.title'
                              )} *`"
                              autocomplete="off"
                              outlined
                              dense
                              class="mb-1 font-weight-bold"
                              @input="delayTouch($v.form.lastName)"
                              @blur="$v.form.lastName.$touch"
                              :error-messages="lastNameErrors"
                              maxlength="100"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="pb-0">
                          <v-col cols="12" class="py-0">
                            <v-text-field
                              append-icon="mdi-at"
                              name="email"
                              v-model="form.email"
                              :label="`${$i18n.t(
                                'security.fields.email.title'
                              )} *`"
                              type="email"
                              autocomplete="off"
                              outlined
                              dense
                              class="mb-1 font-weight-bold"
                              @input="delayTouch($v.form.email)"
                              @blur="$v.form.email.$touch"
                              :error-messages="emailErrors"
                              maxlength="100"
                            ></v-text-field>
                            <v-text-field
                              name="phone"
                              v-model="form.phone"
                              :label="`${$i18n.t(
                                'security.fields.phone.title'
                              )} *`"
                              type="phone"
                              autocomplete="off"
                              outlined
                              dense
                              class="mb-1 font-weight-bold"
                              @input="delayTouch($v.form.phone)"
                              @blur="$v.form.phone.$touch"
                              :error-messages="phoneErrors"
                              maxlength="20"
                            >
                              <template v-slot:append>
                                <div style="width: 32px">
                                  <span
                                    class="flag-icon"
                                    v-if="countryCode"
                                    :class="`flag-icon-${countryCode.toLowerCase()}`"
                                    style="font-size: 1.9rem"
                                  ></span>
                                  <span v-else
                                    ><v-icon>mdi-phone-outline</v-icon></span
                                  >
                                </div>
                              </template>
                            </v-text-field>
                            <v-select
                              :items="$objectPath.get(regions, $i18n.locale)"
                              name="region"
                              v-model="form.region"
                              dense
                              class="mb-1 font-weight-bold"
                              @input="delayTouch($v.form.region)"
                              @blur="$v.form.region.$touch"
                              :error-messages="regionErrors"
                              v-if="countryCode === 'CM'"
                              outlined
                              :label="`${$t('security.fields.region.title')} *`"
                            ></v-select>
                            <v-text-field
                              :append-icon="
                                !form.password
                                  ? 'mdi-lock-outline'
                                  : 'mdi-eye-outline'
                              "
                              name="password"
                              v-model="form.password"
                              :label="`${$t(
                                'security.fields.password.title'
                              )} *`"
                              :type="passwordType"
                              autocomplete="off"
                              outlined
                              dense
                              class="mb-1 font-weight-bold"
                              maxlength="30"
                              @input="delayTouch($v.form.password)"
                              @blur="$v.form.password.$touch"
                              :error-messages="passwordErrors"
                              @click:append="changePasswordType()"
                            ></v-text-field>
                            <v-text-field
                              append-icon="mdi-lock-outline"
                              name="confirmPassword"
                              v-model="form.confirmPassword"
                              :label="`${$t(
                                'security.fields.confirm_password.title'
                              )} *`"
                              :type="passwordType"
                              autocomplete="off"
                              outlined
                              dense
                              class="mb-1 font-weight-bold"
                              maxlength="30"
                              @input="delayTouch($v.form.confirmPassword)"
                              @blur="$v.form.confirmPassword.$touch"
                              :error-messages="confirmPasswordErrors"
                            ></v-text-field>
                            <v-checkbox
                              v-model="term"
                              color="primary"
                              hide-details
                              class="mt-0 text-subtitle-2 pt-0 terms mb-6"
                            >
                              <template v-slot:label>
                                <a
                                  :href="termsLink"
                                  @click.stop
                                  target="_blank"
                                  class="text-subtitle-2 font-weight-bold ml-1"
                                  >{{ $t("security.fields.terms.title") }}</a
                                ></template
                              >
                            </v-checkbox>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="mt-1 px-8 mb-0 align-center">
                        <v-btn
                          color="primary"
                          type="submit"
                          block
                          :loading="loading"
                        >
                          <span class="px-2 font-weight-bold text-subtitle-2">
                            <span class="secondary-font">
                              {{ $t("security.btn.register.title") }}
                            </span>
                          </span>
                        </v-btn>
                      </v-card-actions>
                      <v-card-text class="text-center py-0 my-2">
                        <span
                          class="font-weight-bold"
                          style="font-size: 1.05rem"
                        >
                          <span>{{
                            $t("security.btn.login.description")
                          }}</span>
                          <router-link
                            class="ml-2"
                            text
                            :to="$i18n.getRouteLink('login')"
                            >{{ $t("security.btn.login.title") }}</router-link
                          >
                        </span>
                      </v-card-text>
                      <v-card-actions class="mb-2 pb-0 mt-8 px-0">
                        <v-spacer></v-spacer>
                        <lang :btnOptions="{ 'x-small': true }"></lang>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
              <copyright></copyright>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Lang from "./../commons/lang";
import Vue from "vue";
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import Copyright from "./coypright";
import {
  email,
  required,
  maxLength,
  minLength,
  helpers,
  sameAs,
  requiredIf,
} from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import store from "./../../store";

const tokenName = process.env.VUE_APP_TOKEN_NAME || "_security";
const termsLink =
  process.env.VUE_APP_TERMS_LINK || "https://lmtgroup.com/privacy-policy";

export default {
  mixins: [FormDelayTouchMixin],
  async created() {
    // init cameroon regions
    await this.setRegions();
  },
  data: () => ({
    form: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      password: null,
      confirmPassword: null,
      region: null,
    },
    phoneIsValid: false,
    countryCode: null,
    term: false,
    loading: false,
    termsLink: termsLink,
    passwordType: "password",
  }),
  validations() {
    return {
      form: {
        firstName: {
          required,
          maxLength: maxLength(100),
        },
        lastName: {
          required,
          maxLength: maxLength(100),
        },
        email: {
          required,
          email,
          maxLength: maxLength(100),
          isUnique: async (value) => {
            if (!helpers.req(value)) return true;

            try {
              await store.dispatch("request", {
                method: "post",
                url: "/api/auth/email-exists",
                data: { email: value },
              });
              return false;
            } catch (error) {
              if (error.response) {
                if (error.response.status === 404) {
                  return true;
                }
              }
              return false;
            }
          },
        },
        phone: {
          required,
          matchPhone: (value) => !helpers.req(value) || this.phoneIsValid,
        },
        region: {
          required: requiredIf(function () {
            return this.countryCode === "CM";
          }),
        },
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(30),
          matchPassword: helpers.regex("pass", Vue.$utils.regexPassword),
        },
        confirmPassword: {
          sameAsPassword: sameAs("password"),
        },
      },
      term: {
        sameAs: sameAs(() => true),
      },
    };
  },
  metaInfo() {
    return {
      title: this.$t("security.register.title"),
    };
  },
  methods: {
    async register() {
      this.notify({ status: false });

      // check if form is valid
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.notify({
          message: this.$t("error_codes.bad_request"),
          right: false,
          centered: true,
        });
        return;
      }

      this.$v.term.$touch();
      if (this.$v.term.$invalid) {
        this.notify({
          message: this.$t("security.fields.terms.required"),
          right: false,
          centered: true,
        });
        return;
      }

      this.loading = true;

      try {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha("register");
        const response = await this.request({
          url: "/api/auth/register",
          method: "post",
          data: {
            email: this.form.email,
            password: this.form.password,
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            phone: this.form.phone,
            region: this.form.region,
          },
          headers: {
            recaptcha: token,
          },
          messages: {
            500: true,
            400: this.$t("error_codes.bad_request"),
            422: (error) => {
              let message = "error_codes.error_occured";
              switch (error.code) {
                case "E101":
                  message = "security.fields.phone.invalid";
                  break;
                case "E100":
                  message = "security.fields.email.exists";
                  break;
                case "E103":
                case "E104":
                  message = "security.fields.region.invalid";
                  break;
              }
              return this.$t(message);
            },
          },
          notification: {
            right: false,
            center: true,
          },
        });
        const tokens = response.data;
        this.$auth.token(
          tokenName,
          `${tokens.access_token}||${tokens.refresh_token}`
        );
        this.$router.push({ name: "register-verify" });
      } catch (error) {
        // empty
      }
      this.loading = false;
    },
    changePasswordType() {
      if(!this.form.password) return;
      if (this.passwordType === "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    ...mapActions({
      notify: "notification/notify",
      request: "request",
      setRegions: "setRegions",
    }),
  },
  components: {
    Copyright,
    Lang,
  },
  computed: {
    firstNameErrors() {
      const errors = [];

      if (!this.$v.form.firstName.$dirty) return errors;

      !this.$v.form.firstName.required &&
        errors.push(this.$t("security.fields.first_name.required"));
      !this.$v.form.firstName.maxLength &&
        errors.push(
          this.$t("security.fields.first_name.max_length", {
            max: this.$v.form.firstName.$params.maxLength.max,
          })
        );

      return errors;
    },
    lastNameErrors() {
      const errors = [];

      if (!this.$v.form.lastName.$dirty) return errors;

      !this.$v.form.lastName.required &&
        errors.push(this.$t("security.fields.last_name.required"));
      !this.$v.form.lastName.maxLength &&
        errors.push(
          this.$t("security.fields.last_name.max_length", {
            max: this.$v.form.lastName.$params.maxLength.max,
          })
        );

      return errors;
    },
    emailErrors() {
      const errors = [];

      if (!this.$v.form.email.$dirty) return errors;

      !this.$v.form.email.required &&
        errors.push(this.$t("security.fields.email.required"));
      !this.$v.form.email.email &&
        errors.push(this.$t("security.fields.email.invalid"));
      !this.$v.form.email.maxLength &&
        errors.push(this.$t("security.fields.email.invalid"));
      !this.$v.form.email.isUnique &&
        errors.push(this.$t("security.fields.email.exists"));
      return errors;
    },
    phoneErrors() {
      const errors = [];

      if (!this.$v.form.phone.$dirty) return errors;

      !this.$v.form.phone.required &&
        errors.push(this.$t("security.fields.phone.required"));
      !this.$v.form.phone.matchPhone &&
        errors.push(this.$t("security.fields.phone.invalid"));

      return errors;
    },
    regionErrors() {
      const errors = [];

      if (!this.$v.form.region.$dirty) return errors;

      this.$objectPath.has(this.$v.form.region, "required") &&
        !this.$v.form.region.required &&
        errors.push(this.$t("security.fields.region.required"));
      return errors;
    },
    passwordErrors() {
      const errors = [];

      if (!this.$v.form.password.$dirty) return errors;

      !this.$v.form.password.required &&
        errors.push(this.$t("security.fields.password.required"));

      !this.$v.form.password.minLength &&
        errors.push(
          this.$t("security.fields.password.min_length", {
            min: this.$v.form.password.$params.minLength.min,
          })
        );

      !this.$v.form.password.maxLength &&
        errors.push(
          this.$t("security.fields.password.max_length", {
            max: this.$v.form.password.$params.maxLength.max,
          })
        );

      !this.$v.form.password.matchPassword &&
        errors.push(this.$t("security.fields.password.invalid"));

      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];

      if (!this.$v.form.confirmPassword.$dirty) return errors;
      !this.$v.form.confirmPassword.sameAsPassword &&
        errors.push(this.$t("security.fields.confirm_password.same_as"));

      return errors;
    },
    ...mapGetters({ regions: "regions" }),
  },
  watch: {
    "form.phone"(phone) {
      this.phoneIsValid = false;
      if (!phone) {
        this.countryCode = null;
      }

      if (phone.startsWith("00")) phone = phone.replace(/^(0){2}/, "");
      if (!phone.startsWith("+")) phone = `+${phone}`;
      const number = this.$awesomenumber(phone);
      this.countryCode = number.getRegionCode();
      if (number.isValid()) {
        this.phoneIsValid = true;
      }
    },
  },
};
</script>